import en from '~/locales/en.json' // english
import fr from '~/locales/fr.json' // french
import frCA from '~/locales/fr-CA.json' // canadian-french

export default {
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  detectBrowserLanguage: false,
  messages: {
    en,
    fr,
    'fr-CA': frCA
  },
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'GBP',
        notation: 'standard'
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      },
      percent: {
        style: 'percent',
        useGrouping: false
      }
    },
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'USD',
        notation: 'standard'
      }
    },
    'en-AU': {
      currency: {
        style: 'currency',
        currency: 'AUD',
        notation: 'standard'
      }
    },
    fr: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        notation: 'standard'
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      },
      percent: {
        style: 'percent',
        useGrouping: false
      }
    },
    'fr-CA': {
      currency: {
        style: 'currency',
        currency: 'CAD',
        notation: 'standard'
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      },
      percent: {
        style: 'percent',
        useGrouping: false
      }
    }
  }
}
